<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Qu’est-ce qu’un Ask for it ? 
                p.content(v-if="toggleQuestions[0] === true") 
                    | Lorsqu'un utilisateur ne trouve pas une information, il peut poser une question en créant un Ask for it et l'adresser aux autres utilisateurs de la plateforme pour obtenir une réponse. 
                    br
                    | Une fois un Ask for it créé, les utilisateurs ayant au moins une des compétences ou appartenant à au moins un des chapitres taggés dans le Ask for it sont notifiés pour y répondre.
                    br
                    | Tout le monde peut répondre à un Ask for it qui est dans la section “Tous”. 
                    br
                    | Il est possible de Suivre un Ask for it, je serais alors notifié dès qu’une nouvelle réponse y sera apportée. 
            
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Comment créer un Ask for it ? 
                p.content(v-if="toggleQuestions[1] === true") 
                    | Cliquez sur le bouton “Créer” en haut à droite de n’importe quelle page de la plateforme. 
                    br
                    | Cliquez sur « Ask for it ».
                    br
                    | Renseignez le contenu du Ask for it dans la zone de texte prévue à cet effet.
                    br
                    | Vous pouvez également ajouter des pièces jointes. 
                    br
                    | Ajoutez des tags (compétences et/ ou chapitres) au Ask for it grâce au moteur de recherche (au moins un tag nécessaire) pour cibler les utilisateurs de la plateforme qui pourront vous répondre.
                    br
                    | Cliquez sur le bouton « Publier » pour que le Ask for it puisse être envoyé et que les personnes capables d’y répondre soient notifiées de votre question. 

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 What is Ask for it?
                p.content(v-if="toggleQuestions[0] === true") 
                    | When a user can not find a information, he can create an "Ask for it" and send it to internal "Experts" for asking an answer. 
                    br
                    | Once an "Ask for it" is created, users having at least one of the selected skills or belonging to at least one of the tagged chapters will receive a notification to answer it.
                    br
                    | Everyone can answer an "Ask for it" which is in the "All" section, for those question attached to one or more skills, but no user is linked to it. 
                    br
                    | It is possible to Follow an "Ask for it" , you will be notified when a new answer is given. 
            
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 How to create an "Ask for it"? 
                p.content(v-if="toggleQuestions[1] === true") 
                    | Click the "Create" button at the top right of any page on the platform. 
                    br
                    | Click "Ask for it". 
                    br
                    | Fill in the content of "Ask for it" in the provided area. 
                    br
                    | You could also add attachments. 
                    br
                    | Add tags (skills and / or chapters) to Ask for it through the search engine (at least one tag required) to target users of the platform who will be able to answer you.
                    br
                    | Click "Publish" button to send it to people who are able to answer it.

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 你问我答是什么？
                p.content(v-if="toggleQuestions[0] === true")
                    | 当用户没有搜索到有用的信息时，可以创建"你问我答"，并将其发送给公司内部的"专家"，以便询问答案。
                    br
                    | 一旦创建了一个"你问我答"，拥有至少一个选定的技能或至少属于一个标签小组的用户会收到这个"你问我答"的通知，进行回答。
                    br
                    | 每个人都可以回答在"全部"部分的"你问我答"，这些问题需要一个或多个技能，但没有用户在他们的资料中添加这些技能。
                    br
                    | 可以关注某些"你问我答"，当有新的回答时，你会收到通知。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 如果创建新的你问我答？
                p.content(v-if="toggleQuestions[1] === true")
                    | 1. 点击平台上任意页面右上方的 "创建 "按钮。
                    br
                    | 2. 点击 "你问我答"。
                    br
                    | 3. 填入 "你问我答 "的描述。
                    br
                    | 4. 也可以添加附件。
                    br
                    | 5. 通过搜索栏为 "你问我答 "添加标签（技能，小组），以向相关用户发送该问题，以便他们回答。
                    br
                    | 6. 点击 "发布 "按钮，将其发送给能够回答它的人。
            
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            toggleQuestions: []
        }
    }, 

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }
})
</script>

<style lang="scss" scoped>
@import './question.scss';

</style>